require('./bootstrap');

$(document).ready(function(){
    if ($(this).scrollTop() > 50) {
        $('.mainnav').addClass('scrolled');
        $('.body').addClass('scrolled');
    } else {
        $('.mainnav').removeClass('scrolled');
        $('.body').removeClass('scrolled');
    }

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#slideInNav').toggleClass("show");
        $('#pageContent').toggleClass("shifted");
        $('body').toggleClass("nav-open");
    });
    $("#mainContent").click(function(){
        $("#pageContent").removeClass("shifted");
        $('#slideInNav').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('body').removeClass("nav-open");
    });

    $(window).scroll(function(){
        if ($(this).scrollTop() > 50) {
            $('.mainnav').addClass('scrolled');
            $('.body').addClass('scrolled');
        } else {
            $('.mainnav').removeClass('scrolled');
            $('.body').removeClass('scrolled');
        }
    });

    $(".start-bg-video").hover(
        function () {
            $(this).addClass("box-hover");
        },
        function () {
            $(this).removeClass("box-hover");
        }
    );
    $(".start-bg-video").hover(function () {
        $("#bgVideo").toggleClass("box-hover");
        $(".section-rotating-text").toggleClass("noBg");
    });

    $("#other_text").on("input", function() {
        if($(this).val().length > 0) {
            $('#other').prop('checked', true);
        }else{
            $('#other').prop('checked', false);
        }
    });

});
